<template>
  <div class="iq-sidebar" >
    <div class="iq-sidebar-logo d-flex justify-content-between">
      <router-link :to="homeURL">
        <img :src="require('../../../assets/images/12bookylogo.png')" class="img-fluid" alt="logo" style="height:35px">
      </router-link>
      <div class="iq-menu-bt-sidebar" v-if="toggleButton">
        <div class="iq-menu-bt align-self-center">
          <div class="wrapper-menu" @click="miniSidebar">
            <div class="main-circle"><i class="ri-close-fill"></i></div>
            <div class="hover-circle"><i class="ri-close-fill"></i></div>
          </div>
        </div>
      </div>
    </div>
    <div id="sidebar-scrollbar">
      <nav class="iq-sidebar-menu" :class="horizontal ? 'd-xl-none' : ''">
        <CollapseMenu :items="items" :open="true" :horizontal="horizontal" :sidebarGroupTitle="sidebarGroupTitle"/>
      </nav>
      <div class="p-2" style="display: none">
        <b style="color:red">Nu esti activ in aplicatia de client. <router-link to="/app/account">Fa upgrade</router-link></b>
      </div>
      <div class="p-3" v-if="apiToken">
        {{ $t('customer_.share_availability') }}:
        <a :href="'https://www.facebook.com/sharer/sharer.php?u=' + shareLink" target="_blank">
          <i class="ri-facebook-circle-fill" style="font-size: 30px; vertical-align: sub;"></i>
        </a>
        <a :href="'http://twitter.com/share?url=' + shareLink" target="_blank">
          <i class="ri-twitter-fill" style="font-size: 30px; vertical-align: sub;"></i>
        </a>
        <a :href="'https://www.linkedin.com/sharing/share-offsite/?url=' + shareLink" target="_blank">
          <i class="ri-linkedin-box-fill" style="font-size: 30px; vertical-align: sub;"></i>
        </a>
      </div>
      <div class="p-3"></div>
    </div>
  </div>
  <!-- TOP Nav Bar -->
</template>

<script>
import CollapseMenu from '../menus/CollapseMenu'
import { APPNAME } from '../../../config/pluginInit'
import Axios from 'axios'
import { API } from '@/config/api.config'
export default {
  name: 'Sidebar',
  props: {
    homeURL: { type: Object, default: () => ({ name: 'app.dashboard' }) },
    items: { type: Array },
    logo: { type: String, default: require('../../../assets/images/12book.png') },
    horizontal: { type: Boolean },
    toggleButton: { type: Boolean, default: true },
    logoShow: { type: Boolean, default: true },
    onlyLogo: { type: Boolean, default: false },
    onlyLogoText: { type: Boolean, default: false },
    sidebarGroupTitle: { type: Boolean, default: true },
    shareLink: { type: String, default: '' }
  },
  components: {
    CollapseMenu
  },
  mounted () {
    if (this.apiToken) {
      this.getPlanInfo()
    }
  },
  methods: {
    miniSidebar () {
      this.$emit('toggle')
    },
    getPlanInfo () {
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_GET_COMPANY_PLAN_INFO)
        .then((response) => {
          if (response.data.status === 'success') {
            this.planId = response.data.planInfo.id
          }
        })
        .catch((error) => {
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    }
  },
  data () {
    return {
      appName: APPNAME,
      apiToken: localStorage.getItem('api_token') || '',
      planId: 1
    }
  }
}
</script>

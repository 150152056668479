<template>
  <div >
    <Loader />
    <div class="wrapper">
      <!-- Sidebar  -->
      <Customizer @onLogo="changeLogo" />

      <SmallSidebar v-if="$route.name === 'dashboard.home-5'" :logo="SmallSidebarLogo" @toggle="sidebarMini" />
      <Sidebar :items="verticalMenu" :logo="logo" :onlyLogo="onlyLogo" :onlyLogoText="onlyLogoText" @toggle="sidebarMini" :toggleButton="toggleSideIcon" :sidebarGroupTitle="sidebarGroupTitle" :shareLink="shareLink" />
      <!-- TOP Nav Bar -->
      <DefaultNavBar title="Dashboard" :homeURL="{ name: 'app.dashboard' }" :sidebarGroupTitle="sidebarGroupTitle" @toggle="sidebarMini" :logo="logo">
        <template slot="responsiveRight">
          <ul class="navbar-nav ml-auto navbar-list">
            <li class="nav-item" v-nav-toggle>
              <a class="search-toggle iq-waves-effect language-title" href="#"><img :src="selectedLang.image" alt="img-flaf" class="img-fluid mr-1" style="height: 16px; width: 16px;" /> {{ selectedLang.title }} <i class="ri-arrow-down-s-line"></i></a>
              <div class="iq-sub-dropdown">
                <a class="iq-sub-card" href="javascript:void(0)" v-for="(lang, i) in langsOptions" :key="`Lang${i}`" @click="langChange(lang)">
                  <img :src="lang.image" alt="img-flaf" class="img-fluid mr-2" />{{ lang.title }}
                </a>
              </div>
            </li>
            <li class="nav-item" v-nav-toggle>
              <router-link tag="a" v-if="customerApiToken" :to="{ name: 'customer.messages' }" class="search-toggle iq-waves-effect">
                <lottie  :option="require('../assets/images/small/lottie-bell')" id="lottie-beil" />
                <span v-if="notificationNumber" class="bg-danger dots"></span>
              </router-link>
              <router-link tag="a" v-if="!customerApiToken" :to="{ name: 'app.email' }" class="search-toggle iq-waves-effect">
                <lottie  :option="require('../assets/images/small/lottie-bell')" id="lottie-beil" />
                <span v-if="notificationNumber" class="bg-danger dots"></span>
              </router-link>
              <div class="iq-sub-dropdown" v-if="!customerApiToken">
                <div class="iq-card shadow-none m-0">
                  <div class="iq-card-body p-0 ">
                    <div class="bg-primary p-3">
                      <h5 class="mb-0 text-white">{{ $t('homepage.allNotifications') }}<small class="badge  badge-light float-right pt-1">{{ notificationNumber }}</small></h5>
                    </div>
                    <a href="#" class="iq-sub-card" v-for="item in notification" :key="item.token">
                      <div class="media align-items-center">
                        <div class="media-body ml-3">
                          <h6 class="mb-0 ">{{ item.from }}</h6>
                          <small class="float-right font-size-12">{{ item.created_at }}</small>
                          <p class="mb-0">{{ item.message.substring(0,40) + '...' }}</p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </li>
            <li class="nav-item d-none" v-if="customerApiToken">
              <a href="#" @click.stop="shoopingcart = !shoopingcart" class="search-toggle iq-waves-effect">
                <i class="ri-shopping-cart-fill" style="position: relative">
                  <span class="top-badge" v-if="productsInBasket > 0">{{ productsInBasket }}</span>
                </i>
              </a>
            </li>
          </ul>
        </template>
        <template slot="right">
          <ul class="navbar-list" >
            <li class="" v-nav-toggle>
              <a href="#" class="search-toggle iq-waves-effect d-flex align-items-center bg-primary rounded" >
                <img :src="userProfilePicture" style="object-fit: cover" class="img-fluid rounded mr-3" alt="user">
                <div class="caption">
                  <h6 class="mb-0 line-height text-white">{{ this.userName }}</h6>
                </div>
              </a>
              <div class="iq-sub-dropdown iq-user-dropdown" >
                <div class="iq-card shadow-none m-0">
                  <div class="iq-card-body p-0 ">
                    <div class="bg-primary p-3">
                      <h5 class="mb-0 text-white line-height">Hello {{ this.userName }}</h5>
                      <span class="text-white font-size-12">available</span>
                    </div>
                    <div class="d-sm-none row m-0 p-0">
                      <div class="col-10 m-0 p-0">
                        <span class="iq-sub-card d-block d-sm-none">
                          <a class="search-toggle iq-waves-effect" href="#" @click="mobileOpenedLang = !mobileOpenedLang ? true : false "><img :src="selectedLang.image" alt="img-flaf" class="img-fluid mr-1" style="height: 16px; width: 16px;" /> {{ selectedLang.title }} <i class="ri-arrow-down-s-line"></i></a>
                          <div class="iq-sub-dropdown language-title-mobile" v-show="mobileOpenedLang">
                            <a class="iq-sub-card" href="javascript:void(0)" v-for="(lang, i) in langsOptions" :key="`Lang${i}`" @click="langChange(lang); mobileOpenedLang = false">
                              <img :src="lang.image" alt="img-flaf" class="img-fluid mr-2" />{{ lang.title }}
                            </a>
                          </div>
                        </span>
                      </div>
                      <div class="col-2 mt-2 p-0">
                        <router-link tag="a" v-if="customerApiToken" :to="{ name: 'customer.messages' }" class="search-toggle iq-waves-effect">
                          <lottie  :option="require('../assets/images/small/lottie-bell')" id="lottie-beil" />
                          <span v-if="notificationNumber" class="bg-danger dots"></span>
                        </router-link>
                        <router-link tag="a" v-if="!customerApiToken" :to="{ name: 'app.email' }" class="search-toggle iq-waves-effect">
                          <lottie  :option="require('../assets/images/small/lottie-bell')" id="lottie-beil" />
                          <span v-if="notificationNumber" class="bg-danger dots"></span>
                        </router-link>
                      </div>
                    </div>

                    <span class="iq-sub-card iq-bg-primary-hover" v-if="!customerApiToken">
                      <router-link :to="{ name: 'employee.edit', params: { id: this.userId }}">
                      <div class="media align-items-center">
                        <div class="rounded iq-card-icon iq-bg-primary">
                          <i class="ri-file-user-line"></i>
                        </div>
                        <div class="media-body ml-3">
                          <h6 class="mb-0 ">{{ $t('homepage.my_profile') }}</h6>
                          <p class="mb-0 font-size-12">{{ $t('homepage.view_personal_profile_details') }}</p>
                        </div>
                      </div>
                      </router-link>
                    </span>
                    <span class="iq-sub-card iq-bg-primary-hover" v-if="!customerApiToken">
                      <router-link :to="{ name: 'app.company' }">
                      <div class="media align-items-center">
                        <div class="rounded iq-card-icon iq-bg-primary">
                          <i class="ri-building-fill"></i>
                        </div>
                        <div class="media-body ml-3">
                          <h6 class="mb-0 ">{{ $t('homepage.company_settings') }}</h6>
                          <p class="mb-0 font-size-12">{{ $t('homepage.edit_company_settings') }}</p>
                        </div>
                      </div>
                      </router-link>
                    </span>
                    <div class="d-inline-block w-100 text-center p-3">
                      <a class="bg-primary iq-sign-btn" href="javascript:void(0)" @click="logout" role="button">{{ $t('homepage.signout') }}<i class="ri-login-box-line ml-2"></i></a>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </template>
      </DefaultNavBar>
      <!-- TOP Nav Bar END -->
      <div id="content-page" class="content-page" >
        <transition name="router-anim" :enter-active-class="`animated ${animated.enter}`" mode="out-in"
                    :leave-active-class="`animated ${animated.exit}`">
          <router-view :key="$route.path" v-on:upgrade_message="upgradeMessage" v-on:set_userdetails="setCustomerImageAndName" v-on:update_products="updateProducts" v-on:update_wishlist="updateWishlist"/>
        </transition>
      </div>
    </div>

    <LayoutFooter v-if="device !== 'ios'" />
    <div class="alert alert-warning mb-0 fixed-bottom" role="alert" v-if="!isActive">
      {{ $t('activate.to_activate') }}<a v-if="resendEmail === false" class="ml-2 text-primary" @click="resendEmailClick" role="button">{{ $t('activate.resend') }}</a>
      <span class="ml-2 text-primary" v-if="resendEmail === true">{{ $t('activate.email_sent') }} {{ emailSentTo }}</span>
    </div>
    <div class="alert alert-danger mb-0 fixed-bottom" role="danger" v-if="expireNow">
      {{ $t('customer_.your_subscription_will_expire', { subscription: expireNowPlan, days: expireNow}) }}
      <router-link :to="'/app/buyplan/' + expireNowPlanId" class="text-black">
        &nbsp;{{ $t('customer_.renew') }}
      </router-link>
    </div>

    <b-modal id="globalUpgrade" centered hide-footer :title="$t('pay.more_features_title')">
      <div class="row">
        <div class="col-sm-6 my-auto">
          <img :src="require('../assets/images/booking/booking-01.png')" width="100%"/>
        </div>
        <div class="col-sm-6">
          <h4 class="wm-title py-1">{{ $t('pay.more_features') }} {{ this.nextPlan.name}}</h4>
          {{ $t('pay.more_features_text') }}
          <ul class="list-unstyled line-height-1 mb-0 planList">
            <li><i class="ri-check-line"></i>{{ this.nextPlan.bookings === '-1' ? $t('pay.unlimited') : this.nextPlan.bookings }} {{ $t('pay.bookings_per_day') }}</li>
            <li><i class="ri-check-line"></i>{{ this.nextPlan.services === '-1' ? $t('pay.unlimited') : this.nextPlan.services }} {{ $t('pay.services') }}</li>
            <li><i class="ri-check-line"></i>{{ this.nextPlan.employees === '-1' ? $t('pay.unlimited') : this.nextPlan.employees }} {{ $t('pay.employees') }}</li>
            <li><i class="ri-check-line"></i>{{ $t('pay.unlimited_customers') }}</li>
            <li><i class="ri-check-line"></i>{{ this.nextPlan.messages === '-1' ? $t('pay.unlimited') : '' }} {{ $t('pay.messages') }}</li>
            <li><i class="ri-check-line"></i>{{ this.nextPlan.emails === '-1' ? $t('pay.unlimited') : this.nextPlan.emails }} {{ $t('pay.emails') }}</li>
            <li><i class="ri-check-line"></i>{{ this.nextPlan.sms === '-1' ? $t('pay.unlimited') : this.nextPlan.sms }} {{ $t('pay.sms') }}</li>
            <li><i class="ri-check-line"></i>{{ $t('pay.app_access') }}</li>
            <li><i class="ri-check-line"></i>{{ $t('pay.website') }}</li>
            <li><i class="ri-check-line"></i>{{ $t('pay.reports') }}</li>
            <li><i class="ri-check-line"></i>{{ $t('pay.reports') }}</li>
            <li><i class="ri-check-line"></i>{{ $t('pay.payments') }}</li>
          </ul>
        </div>
      </div>
      <router-link :to="'/app/buyplan/' + this.nextPlan.id">
        <div class="upgrade_button">
          {{ $t('pay.upgrade') }}
        </div>
      </router-link>
    </b-modal>

    <div class="firstTimeHelper" v-if="isFirstTime">
      <div class="">
        <img :src="require('../assets/images/12bookylogo.png')" width="300" /> <br>
        {{ $t('helper.welcome_to') }} <b>12booky.com</b><br>
        {{ $t('helper.gathering_info') }}<br>
        {{ $t('helper.ask_questions') }} <a href="mailto:office@12booky.com" style="color:#ffffff">office@12booky.com</a>
        <div class="step1 mt-4" v-if="step === 1">
          {{ $t('helper.select_company_type') }}
          <div class="row mt-3">
            <div class="col-sm-3" v-for="(item, key) in companyTypes" :key="key" @click="setCompanyType(item)" >
              <div class="thediv">
                <i class="fas fa-swimmer" v-if="item === 'recreation'"></i>
                <i class="fas fa-clinic-medical" v-if="item === 'medical'"></i>
                <i class="fas fa-tooth" v-if="item === 'dentist'"></i>
                <i class="fas fa-dog" v-if="item === 'k9'"></i>
                <i class="fas fa-running" v-if="item === 'fitness'"></i>
                <i class="fas fa-cut" v-if="item === 'barbershop'"></i>
                <i class="fas fa-person-booth" v-if="item === 'beauty'"></i>
                <i class="fas fa-spa" v-if="item === 'spa'"></i>
                <i class="fas fa-car" v-if="item === 'auto'"></i>
                <i class="fas fa-pen-alt" v-if="item === 'tattoo'"></i>
                <i class="fas fa-wrench" v-if="item === 'installations'"></i>
                <i class="fas fa-pencil-ruler" v-if="item === 'site'"></i>
                {{ $t('helper.' + item) }}
              </div>
            </div>
          </div>
        </div>
        <span class="step2" v-if="step === 2">
          {{ $t('company.country') }} <br>
          <b-form-group label-for="selectcountry">
            <b-form-select v-model="country" :options="countries" id="selectcountry"></b-form-select>
          </b-form-group>
          {{ $t('company.city') }} <br>
          <input type="text" v-model="city" />
          <br><br>
          {{ $t('company.street_address') }} <br>
          <input type="text" v-model="address" /><br><br>
          {{ $t('payment.currency') }} <br>
          <b-form-group label-for="selectcurrency">
            <b-form-select v-model="currency" :options="currencies" id="selectcurrency">
              <template v-slot:first>
                <b-form-select-option :value="null">{{ $t('payment.select_currency') }}</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
          <div class="helperError" v-if="helperError">
            {{ helperError }}
          </div>
          <br><br>
          <div>
            <b-button @click="step = 1" class="stepButton btn-success">{{ $t('helper.previous_step') }}</b-button>
            <b-button @click="goToStep3" class="stepButton btn-success float-right">{{ $t('helper.next_step') }}</b-button>
          </div>
        </span>
        <span class="step3 mt-4" v-if="step === 3">
          <div class="row m-0 p-0">
            <div class="col-6 m-0 p-0">{{ $t('helper.your_services_types') }}: ex. Medical, Barber, etc</div>
            <div class="col-6 m-0 p-0 text-right"><b-button @click="newServiceType" class="btn pt-1 btn-primary">+ {{ $t('services.add_services_type') }}</b-button> </div>
          </div>
          <div v-if="savedServicesType.length > 0" style="margin-bottom: 10px">
            <div v-for="(item, key) in savedServicesType" :key="key" class="serviceView row">
              <div class="col-6">
              {{ item.name }}
              </div>
              <div class="col-6 text-right">
                <span @click="editServiceType(item)" style="color: yellow;font-weight: bold;margin-right: 10px;">{{ $t('customer.edit') }}</span>
                <span @click="deleteServiceType(item)" style="color: #c40000;font-weight: bold;">{{ $t('customer.delete') }}</span>
              </div>
            </div>
          </div>
          <br>
          <div v-if="newServiceTypeActive" class="newService row p-0 m-0">
            <div class="col-10 p-0 m-0">
              <input type="text" v-model="serviceTypeName" :placeholder="$t('reports.service_type')"/>
            </div>
            <div class="col-2 text-right p-0 mt-1">
              <b-button @click="saveServiceType" class="btn-info">{{ $t('helper.save')}}</b-button>
            </div>
          </div>
           <div class="helperError" v-if="helperError">
            {{ helperError }}
          </div>
          <br>
          <div class="mt-4">
            <b-button @click="step = 2" class="stepButton btn-success">{{ $t('helper.previous_step') }}</b-button>
            <b-button @click="goToStep4" class="stepButton btn-success float-right">{{ $t('helper.next_step') }}</b-button>
          </div>
        </span>
        <span class="step3 mt-4" v-if="step === 4">
          <div class="row p-0 m-0">
            <div class="col-10 p-0 m-0">
              {{ $t('helper.your_services') }}: ex. Consult, Haircut
            </div>
            <div class="col-2 p-0 m-0 text-right">
              <b-button @click="newService" class="btn btn-primary">+ {{ $t('calendar.add_service') }}</b-button>
            </div>
          </div>
          <div v-if="savedServices.length > 0" style="margin-bottom: 10px">
            <div v-for="(item, key) in savedServices" :key="key" class="serviceView row p-1">
              <div class="col-8 pl-0">
                {{ item.name }}[{{ item.type }}] - {{ item.price }} {{ currency }} - {{ item.duration }} {{ item.duration_type }}
              </div>
              <div class="col-4 pr-0 text-right">
                 <span @click="editService(item)" style="color: yellow;font-weight: bold;margin-right: 10px;">{{ $t('customer.edit') }}</span>
                <span @click="deleteService(item)" style="color: #c40000;font-weight: bold;">{{ $t('customer.delete') }}</span>
              </div>
            </div>
          </div>

          <div v-if="newServiceActive" class="newService">
            {{ $t('helper.your_services_types') }}
            <b-form-select v-model="serviceTypeOption" :options="savedServicesType" value-field="name" text-field="name">
              <template #first>
                <b-form-select-option value="" disabled>-- {{ $t('helper.new_service_type') }} --</b-form-select-option>
              </template>
            </b-form-select>
            <input type="text" v-model="serviceName" :placeholder="$t('services.service_name')"/>
            <input type="number" v-model="servicePrice" :placeholder="$t('services.service_price')"/>
            <span class="serviceCurrency">{{ currency }}</span>
            <input type="number" v-model="serviceDuration" :placeholder="$t('calendar.duration')"/>
            <b-form-select v-model="serviceDurationType">
                <b-form-select-option value="minutes" selected>{{ $t('services.minutes') }}</b-form-select-option>
                <b-form-select-option value="hours">{{ $t('services.hours') }}</b-form-select-option>
                <b-form-select-option value="days">{{ $t('services.days') }}</b-form-select-option>
                <b-form-select-option value="weeks">{{ $t('services.weeks') }}</b-form-select-option>
                <b-form-select-option value="months">{{ $t('services.months') }}</b-form-select-option>
                <b-form-select-option value="years">{{ $t('services.years') }}</b-form-select-option>
            </b-form-select>
            <b-button @click="saveService" class="btn-info">{{ $t('helper.save')}}</b-button>
          </div>

          <div class="helperError" v-if="helperError">
            {{ helperError }}
          </div>
          <br>
          <div class="mt-4">
            <b-button @click="step = 3" class="stepButton btn-success">{{ $t('helper.previous_step') }}</b-button>
            <b-button @click="goToStep5" class="stepButton btn-success float-right">{{ $t('helper.next_step') }}</b-button>
          </div>
        </span>
        <span class="step3 mt-4" v-if="step === 5">
          {{ $t('helper.your_first_employee_name') }}
          <input type="text" v-model="employeeName" :placeholder="$t('helper.employee_name')"/>
          <br>
          <div class="helperError" v-if="helperError">
            {{ helperError }}
          </div>
          <div class="mt-4">
            <b-button @click="step = 3" class="stepButton btn-success">{{ $t('helper.previous_step') }}</b-button>
            <b-button @click="finalizeHelper" class="stepButton btn-success float-right">{{ $t('helper.submit') }}</b-button>
          </div>
        </span>

        <span class="step3 mt-4" v-if="step === 6">
          <h2 style="text-align:center; color: white;">{{ $t('helper.success') }}</h2>
          <span style="text-align:center; color: white; display:block; width: 100%;">{{ $t('helper.redirect') }}</span>
        </span>
      </div>
    </div>
    <v-navigation-drawer
      v-model="shoopingcart"
      absolute
      temporary
      right
      :width="400"
      v-if="customerApiToken"
      style="z-index: 999999;display:none;"
    >
      <div style="height: 100%;display: none;">
        <div style="background: #15b0b7; color: #fff;" class="row m-0">
          <div class="col-sm-2">
            <i class="ri-shopping-cart-fill" style="font-size: 40px;"></i>
          </div>
          <div class="col-sm-10">
            <div style="font-size: 15px;">My basket</div>
            <div style="font-size: 20px;" v-if="productsInBasket === 0">0 Lei</div>
            <div style="font-size: 20px;" v-if="productsInBasket > 0">{{ totalProducts }} Lei</div>
          </div>
        </div>
        <div class="products">
          <div v-if="productsInBasket > 0">
            <div v-for="(item, key) in basketProducts" :key="key" class="row p-2">
              <div class="col-sm-4">
                <img :src="item.productImage || require('../assets/images/product-placeholder.png')" style="width: 100%;">
              </div>
              <div class="col-sm-8">
                <router-link tag="a" :to="{ name: 'customer.product', params: { product: item.productId }}">
                  {{ item.productName }}
                </router-link>
                <br>
                <span style="color:black">{{ item.quantity }} x {{ item.productPrice }} {{ item.productCurrency }}</span>
              </div>
            </div>
          </div>
          <div v-if="productsInBasket === 0" class="no_products"> No products yet</div>
        </div>
        <div class="text-center w-100" style="position: absolute; bottom: 30px;">
          <router-link tag="a" :to="{ name: 'customer.cart' }">
            <b-button class="btn-success">Mergi la cos</b-button>
          </router-link>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>
<script>
import { core } from '../config/pluginInit'
import { Users } from '../FackApi/api/chat'
import { mapActions, mapGetters } from 'vuex'
import Loader from '../components/core/loader/Loader'
import Sidebar from '../components/core/sidebars/Sidebar'
import DefaultNavBar from '../components/core/navbars/DefaultNavBar'
import profile from '../assets/images/user/customer_placeholder.png'
import loader from '../assets/images/loader.gif'
import darkLoader from '../assets/images/darkMode/dark-logo.gif'
import Lottie from '../components/core/lottie/Lottie'
import WhiteLogo from '../assets/images/logo-2.png'
import SmallSidebar from '../components/core/sidebars/SmallSidebar'
import LayoutFooter from './Components/LayoutFooter'
import Axios from 'axios'
import { API } from '@/config/api.config'
import Customizer from './Components/Customizer'
import SideBarItems from '../FackApi/json/SideBar'
import SideBarCustomerItems from '../FackApi/json/SideBarCustomer.json'
import { Capacitor } from '@capacitor/core'
import { Browser } from '@capacitor/browser'
import { App } from '@capacitor/app'

export default {
  name: 'VerticleLayout',
  components: {
    LayoutFooter,
    Lottie,
    Loader,
    Sidebar,
    DefaultNavBar,
    SmallSidebar,
    Customizer
  },
  mounted () {
    if (this.customerApiToken) {
      this.verticalMenu = SideBarCustomerItems
      this.getShoppingProducts()
      this.getCustomerDetails()
    } else {
      this.getUserDetails()
    }
    this.$nextTick(function () {
      this.closeTheBrowser()
    })
    this.layoutSetting(this.$route.name)
  },
  computed: {
    ...mapGetters({
      cartCount: 'Ecommerce/cartCountState',
      cartItems: 'Ecommerce/cartState',
      selectedLang: 'Setting/langState',
      langsOptions: 'Setting/langOptionState',
      darkMode: 'Setting/darkModeState',
      rtlMode: 'Setting/rtlModeState',
      colors: 'Setting/colorState'
    }),
    toggleSideIcon () {
      let show = true
      switch (this.$route.name) {
        case 'dashboard.home-3':
        case 'dashboard.home-4':
        case 'dashboard.home-5':
        case 'dashboard.home-6':
          show = false
          break
      }
      return show
    }
  },
  watch: {
    $route: function (to, from) {
      this.layoutSetting(to.name)
    }
  },
  // sidebarTicket
  data () {
    return {
      shoopingcart: '',
      device: Capacitor.getPlatform() || 'web',
      companyToken: localStorage.getItem('company_token') || '',
      newEmployeeActive: false,
      savedEmployees: [],
      editingEmployee: false,
      employeeName: '',
      employeePhone: '',
      employeeEmail: '',
      editingService: false,
      editingServiceType: false,
      savedServices: [],
      savedServicesType: [],
      serviceTypeOption: '',
      serviceName: '',
      serviceTypeName: [],
      servicePrice: '',
      serviceDuration: '',
      serviceDurationType: 'minutes',
      helperError: false,
      address: '',
      city: '',
      country: 'Romania',
      countries: [
        'Romania',
        'USA',
        'Germany',
        'France',
        'Italy',
        'Spain'
      ],
      currency: 'RON',
      currencies: [
        'RON',
        'USD',
        'EUR'
      ],
      newServiceActive: false,
      newServiceTypeActive: false,
      companyServiceType: '',
      isFirstTime: false,
      companyTypes: [],
      limitServices: 0,
      limitEmployees: 0,
      step: 1,
      nextPlan: [],
      options: {
        backdropColor: '#0000007a',
        sequence: [{
          element: '[aria-controls="sidebar.messages"]',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
          placement: 'right'
        }],
        onComplete: function () {

        }
      },
      emailSentTo: '',
      resendEmail: false,
      theDark: false,
      userId: '',
      apiToken: localStorage.getItem('api_token') || '',
      customerApiToken: localStorage.getItem('customer_api_token') || '',
      isActive: true,
      expireNow: 0,
      expireNowPlan: '',
      expireNowPlanId: '',
      userName: '',
      animated: { enter: 'fadeInUp', exit: 'fadeOut' },
      verticalMenu: SideBarItems,
      userProfilePicture: '',
      onlyLogo: false,
      onlyLogoText: false,
      sidebarGroupTitle: true,
      shareLink: 'https://app.12booky.com',
      logo: loader,
      usersList: Users,
      rtl: false,
      SmallSidebarLogo: WhiteLogo,
      message: [],
      notification: [],
      notificationNumber: '',
      userRights: [],
      notBookmarkRouts: [
        'dashboard.home-1',
        'dashboard.home-2',
        'dashboard.home-3',
        'dashboard.home-4',
        'dashboard.home-5',
        'dashboard.home-6'
      ],
      langOption: [
        { title: 'English', value: 'en', image: require('../assets/images/small/flag-01.png') },
        { title: 'Romanian', value: 'ro', image: require('../assets/images/small/romania.png') },
        { title: 'German', value: 'de', image: require('../assets/images/small/flag-05.png') },
        { title: 'French', value: 'fr', image: require('../assets/images/small/flag-200.png') },
        { title: 'Spanish', value: 'es', image: require('../assets/images/small/flag-600.png') },
        { title: 'Italian', value: 'it', image: require('../assets/images/small/italy.png') },
        { title: 'Hungarian', value: 'hu', image: require('../assets/images/small/hungary.png') }
      ],
      upgradeActive: true,
      mobileOpenedLang: false,
      basketProducts: [],
      productsInBasket: 0,
      totalProducts: 0,
      wishlistProducts: [],
      productsInWishlist: 0
    }
  },
  methods: {
    getShoppingProducts () {
      let basketProducts = []
      if (localStorage.getItem('products')) {
        basketProducts = JSON.parse(localStorage.getItem('products'))
        this.basketProducts = basketProducts
        console.log(this.basketProducts)
        this.productsInBasket = this.basketProducts.length
        for (let x = 0; x < this.basketProducts.length; x++) {
          this.totalProducts = this.totalProducts + parseFloat(this.basketProducts[x].productPrice)
        }
      }
    },
    updateProducts () {
      if (localStorage.getItem('products')) {
        this.basketProducts = JSON.parse(localStorage.getItem('products'))
      }
      this.productsInBasket = this.basketProducts.length
    },
    updateWishlist () {
      if (localStorage.getItem('wishlist')) {
        this.wishlistProducts = JSON.parse(localStorage.getItem('wishlist'))
      }
      this.productsInWishlist = this.wishlistProducts.length
    },
    closeTheBrowser () {
      if (this.device === 'ios') {
        App.addListener('appUrlOpen', data => {
          if (data.url.indexOf('onetwobooky://') > -1) {
            Browser.close()
            this.$router.go()
          }
        })
      }
    },
    goToStep3 () {
      if (this.city === '') {
        this.helperError = this.$t('helper.add_city')
      } else if (this.address === '') {
        this.helperError = this.$t('helper.add_address')
      } else {
        this.helperError = false
        this.step = 3
      }
    },
    goToStep4 () {
      if (this.savedServicesType.length === 0) {
        this.helperError = this.$t('helper.add_service_type')
      } else {
        if (this.savedServicesType.length === 1) {
          this.serviceTypeOption = this.savedServicesType
        }
        this.helperError = false
        this.step = 4
      }
    },
    goToStep5 () {
      if (this.savedServices.length === 0) {
        this.helperError = this.$t('services.add_service')
      } else {
        this.helperError = false
        this.step = 5
      }
    },
    finalizeHelper () {
      if (this.employeeName.length === 0) {
        this.helperError = this.$t('helper.add_employee_name')
      } else {
        const postData = {
          category: this.companyServiceType,
          country: this.country,
          city: this.city,
          address: this.address,
          currency: this.currency,
          servicestype: this.savedServicesType,
          services: this.savedServices,
          employees: this.employeeName
        }
        Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
        Axios.post(API.API_FINALIZE_HELPER, postData)
          .then((response) => {
            if (response.data.status === 'success') {
              this.step = 6
              setTimeout(() => {
                window.location.reload()
              }, 3000)
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              this.$router.push({ name: 'auth1.login' })
            }
          })
      }
    },
    editEmployee (item) {
      this.employeeName = item.name
      this.employeePhone = item.phone
      this.employeeEmail = item.email
      this.editingEmployee = item
      this.newEmployeeActive = true
    },
    editService (item) {
      this.serviceTypeOption = item.type
      this.serviceName = item.name
      this.servicePrice = item.price
      this.serviceDuration = item.duration
      this.serviceDurationType = item.duration_type
      this.editingService = item
      this.newServiceActive = true
    },
    editServiceType (item) {
      console.log(item)
      this.serviceName = item.name
      this.editingServiceType = item
      this.newServiceTypeActive = true
    },
    saveEmployee () {
      this.helperError = false
      const reg = /\S+@\S+\.\S+/
      if (this.employeeName === '') {
        this.helperError = this.$t('helper.add_employee_name')
      } else if (this.employeePhone === '') {
        this.helperError = this.$t('helper.add_employee_phone')
      } else if (this.employeeEmail === '') {
        this.helperError = this.$t('helper.add_employee_email')
      } else if (!reg.test(this.employeeEmail)) {
        this.helperError = this.$t('helper.email_not_valid')
      } else {
        if (this.editingEmployee) {
          const arrayIndex = this.savedEmployees.indexOf(this.editingEmployee)
          this.savedEmployees[arrayIndex] = {
            name: this.employeeName,
            phone: this.employeePhone,
            email: this.employeeEmail
          }
        } else {
          this.savedEmployees.push({
            name: this.employeeName,
            phone: this.employeePhone,
            email: this.employeeEmail
          })
        }
        this.newEmployeeActive = false
      }
    },
    saveServiceType () {
      this.helperError = false
      if (this.serviceTypeName === '') {
        this.helperError = this.$t('helper.add_service_name')
      } else {
        console.log(this.serviceTypeName, this.editingServiceType)
        if (this.editingServiceType) {
          const arrayIndex = this.savedServicesType.indexOf(this.editingServiceType)
          this.savedServicesType[arrayIndex] = {
            name: this.serviceTypeName
          }
        } else {
          this.savedServicesType.push({
            name: this.serviceTypeName
          })
        }
        this.newServiceTypeActive = false
      }
    },
    saveService () {
      this.helperError = false
      if (this.serviceTypeOption === '') {
        this.helperError = this.$t('helper.add_service_name')
      } else if (this.serviceName === '') {
        this.helperError = this.$t('helper.add_service_name')
      } else if (this.servicePrice === '') {
        this.helperError = this.$t('helper.add_service_price')
      } else if (this.serviceDuration === '') {
        this.helperError = this.$t('helper.add_service_duration')
      } else {
        if (this.editingService) {
          const arrayIndex = this.savedServices.indexOf(this.editingService)
          this.savedServices[arrayIndex] = {
            type: this.serviceTypeOption,
            name: this.serviceName,
            price: this.servicePrice,
            duration: this.serviceDuration,
            duration_type: this.serviceDurationType
          }
        } else {
          this.savedServices.push({
            type: this.serviceTypeOption,
            name: this.serviceName,
            price: this.servicePrice,
            duration: this.serviceDuration,
            duration_type: this.serviceDurationType
          })
        }
        this.newServiceActive = false
      }
    },
    newEmployee () {
      if (this.limitEmployees > this.savedEmployees.length) {
        this.employeeName = ''
        this.employeePhone = ''
        this.employeeEmail = ''
        this.editingEmployee = false
        this.newEmployeeActive = true
      } else {
        this.helperError = this.$t('helper.limit_employees_reached')
      }
    },
    newService () {
      this.newServiceTypeActive = false
      // if (this.limitServices > this.savedServices.length) {
      this.serviceName = ''
      this.servicePrice = ''
      this.serviceDuration = ''
      this.editingService = false
      this.newServiceActive = true
      // } else {
      //   this.helperError = this.$t('helper.limit_services_reached')
      // }
    },
    newServiceType () {
      this.serviceTypeName = ''
      this.editServicesType = false
      this.editingServiceType = false
      this.newServiceTypeActive = true
      this.newServiceActive = false
    },
    deleteEmployee (item) {
      const arrayIndex = this.savedEmployees.indexOf(item)
      this.savedEmployees.splice(arrayIndex, 1)
      this.newEmployeeActive = false
    },
    deleteServiceType (item) {
      const arrayIndex = this.savedServicesType.indexOf(item)
      this.savedServicesType.splice(arrayIndex, 1)
      this.newServiceTypeActive = false
    },
    deleteService (item) {
      const arrayIndex = this.savedServices.indexOf(item)
      this.savedServices.splice(arrayIndex, 1)
      this.newServiceActive = false
    },
    setCompanyType (type) {
      this.companyServiceType = type
      this.step = 2
    },
    upgradeMessage (nextPlan) {
      this.nextPlan = nextPlan
    },
    setCustomerImageAndName (name, photo) {
      this.userName = name
      if (photo) {
        this.userProfilePicture = photo
      }
    },
    resendEmailClick () {
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_RESEND_EMAIL)
        .then((response) => {
          if (response.data.status === 'success') {
            this.resendEmail = true
            this.emailSentTo = response.data.email
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    getCustomerDetails () {
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.customerApiToken
      Axios.get(API.API_GET_CUSTOMER_LANG_AND_COLORS)
        .then((response) => {
          if (response.data.status === 'success') {
            this.userName = response.data.name
            if (response.data.logo !== '') {
              this.userProfilePicture = response.data.logo
            } else {
              this.userProfilePicture = profile
            }
            if (response.data.adminColors !== '') {
              const theColors = JSON.parse(response.data.adminColors)
              const theDark = theColors.background === true ? 1 : 0
              this.modeChange({ rtl: false, dark: theDark })
              if (theColors.color !== '') {
                document.documentElement.style.setProperty('--iq-primary', theColors.color.primary)
                document.documentElement.style.setProperty(
                  '--iq-primary-light',
                  theColors.color.primaryLight
                )
                if (theColors.background) {
                  document.documentElement.style.setProperty(
                    '--iq-bg-dark-color',
                    theColors.color.bodyBgDark
                  )
                } else {
                  document.documentElement.style.setProperty(
                    '--iq-bg-light-color',
                    theColors.color.bodyBgLight
                  )
                }
              }
            }
            if (response.data.language !== '') {
              const currentLang = this.getCurrentLang(response.data.language)
              console.log(currentLang)
              this.langChangeState(currentLang)
              this.$i18n.locale = currentLang.value
              document.getElementsByClassName('iq-show')[0].classList.remove('iq-show')
            }
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    getAllCountriesAndCurrencies () {
      Axios.get('https://countriesnow.space/api/v0.1/countries/currency')
        .then((response) => {
          if (response.data.error === false) {
            const countryData = response.data.data
            const countryList = []
            const currencyList = []
            for (let x = 0; x < countryData.length; x++) {
              countryList.push(countryData[x].name)
              currencyList.push(countryData[x].currency)
            }
            this.countries = countryList
            this.currencies = currencyList
          }
        })
    },
    getUserDetails () {
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_GET_USER_DETAILS)
        .then((response) => {
          console.log(response)
          if (response.data.status === 'success') {
            this.shareLink = response.data.shareLink
            this.companyTypes = response.data.companyTypes
            if (response.data.firstTime === true) {
              this.isFirstTime = true
              this.limitServices = response.data.availablePlan.services
              this.limitEmployees = response.data.availablePlan.employees
              // get all countries
              this.getAllCountriesAndCurrencies()
            }
            if (response.data.data.account_key !== '' && response.data.data.account_key !== null) {
              this.isActive = false
            } else {
              this.isActive = true
            }
            if (response.data.expires === 1 || response.data.expires === 2 || response.data.expires === 3) {
              this.expireNow = response.data.expires
              this.expireNowPlan = response.data.availablePlan.name
              this.expireNowPlanId = response.data.availablePlan.id
            }
            // update colors if they are
            if (response.data.data.adminColors !== '') {
              const theColors = JSON.parse(response.data.data.adminColors)
              const theDark = theColors.background === true ? 1 : 0
              this.modeChange({ rtl: false, dark: theDark })
              // console.log(theColors.color)
              if (theColors.color !== '') {
                document.documentElement.style.setProperty('--iq-primary', theColors.color.primary)
                document.documentElement.style.setProperty(
                  '--iq-primary-light',
                  theColors.color.primaryLight
                )
                if (theColors.background) {
                  document.documentElement.style.setProperty(
                    '--iq-bg-dark-color',
                    theColors.color.bodyBgDark
                  )
                } else {
                  document.documentElement.style.setProperty(
                    '--iq-bg-light-color',
                    theColors.color.bodyBgLight
                  )
                }
              }
            }
            this.userName = response.data.data.fname + ' ' + response.data.data.lname
            if (response.data.data.logo !== '') {
              this.userProfilePicture = response.data.data.logo
            } else {
              this.userProfilePicture = profile
            }
            this.userId = response.data.data.token || null
            this.notification = response.data.messages
            this.notificationNumber = response.data.countMessages
            localStorage.removeItem('userRights')
            if (response.data.data.userRights !== '') {
              this.userRights = JSON.parse(response.data.data.userRights)
              localStorage.setItem('userRights', JSON.stringify(response.data.data.userRights))
            }
            if (response.data.data.language !== '') {
              const currentLang = this.getCurrentLang(response.data.data.language)
              this.langChangeState(currentLang)
              this.$i18n.locale = currentLang.value
              document.getElementsByClassName('iq-show')[0].classList.remove('iq-show')
            }
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    getCurrentLang (lang) {
      let theLang = lang
      if (lang.includes('"')) {
        const theLang1 = lang.split('"')
        theLang = theLang1[1]
      }
      for (let i = 0; i < this.langOption.length; i++) {
        if (this.langOption[i].value === theLang) {
          return this.langOption[i]
        }
      }
    },
    layoutSetting (routeName) {
      this.modeChange({ rtl: this.rtlMode, dark: this.darkMode })
      this.onlyLogo = true
      this.onlyLogoText = true
      this.sidebarGroupTitle = true
      switch (routeName) {
        case 'dashboard.home-5':
          this.onlyLogoText = true
          this.onlyLogo = false
          break
        case 'dashboard.home-6':
          this.logo = WhiteLogo
          this.onlyLogo = true
          this.onlyLogoText = false
          this.sidebarGroupTitle = false
          break
        default:
          if (this.darkMode) {
            this.logo = darkLoader
          } else {
            this.logo = loader
          }
          break
      }
    },
    changeLogo (e) {
      this.logo = e
    },
    sidebarMini () {
      core.triggerSet()
      this.$store.dispatch('Setting/miniSidebarAction')
    },
    logout () {
      localStorage.removeItem('user')
      localStorage.removeItem('access_token')
      localStorage.removeItem('customer_access_token')
      this.$router.push({ name: 'auth1.login' })
    },
    langChange (lang) {
      let theUrl = ''
      if (this.customerApiToken) {
        theUrl = API.API_SET_ADMIN_CUSTOMER_LANG
        Axios.defaults.headers.common.Authorization = 'bearer ' + this.customerApiToken
      } else {
        theUrl = API.API_SET_ADMIN_LANG
        Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      }

      const postData = {
        lang: lang.value
      }
      Axios.post(theUrl, postData)
        .then((response) => {
          if (response.data.status === 'success') {
            this.langChangeState(lang)
            this.$i18n.locale = lang.value
            document.getElementsByClassName('iq-show')[0].classList.remove('iq-show')
            if (lang.value === 'ar') {
              this.rtlAdd(lang)
            } else {
              this.rtlRemove(lang)
            }
          }
        })
    },
    routerAnimationChange (e) {
      this.animated = e
    },
    ...mapActions({
      removeToCart: 'Ecommerce/removeToCartAction',
      langChangeState: 'Setting/setLangAction',
      rtlAdd: 'Setting/setRtlAction',
      rtlRemove: 'Setting/removeRtlAction',
      modeChange: 'Setting/layoutModeAction'
    })
  }
}
</script>
<style>
  @import url("../assets/css/custom.css");
  @import url("../assets/css/PriceSlider.css");
  #globalUpgrade___BV_modal_body_{
    padding: 0 !important;
  }
  .upgrade_button{
    padding: 10px;
    background: #ffc107;
    text-align: center;
    color: white;
    font-weight: bold;
    cursor: pointer;
  }
  .wm-title{
    color: #ffc107;
  }
  .firstTimeHelper{
    position: fixed;
    top: 0;
    bottom: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: #000000de;
    padding: 10px !important;
    margin: 0 !important;
    text-align: center;
    color: white;
  }
  .step1 .thediv{
    padding: 2px 10px;
    border: 2px solid white;
    border-radius: 3px;
    margin: auto;
    margin-bottom: 10px;
    cursor:pointer;
    font-weight: bold;
    background: white;
    color: #2197c0;
  }
  .step1 .thediv:hover{
    background: #2197c0;
    color: #fff;
  }
  .step1 .thediv .fas{
    font-size: 40px !important;
    display: block !important;
  }
  .step2{
    color: white;
    display:block;
    width: 250px;
    margin: auto;
    margin-top: 20px;
    text-align: left;
  }
  .step2 label{
    color: white !important;
  }
  .step2 input[type="text"]{
    border: 1px solid white;
    width: 100%;
    padding: 3px;
    border-radius: 2px;
    background: white;
  }
  .helperError{
    color: #ff9900;
    font-size: 12px;
    font-weight: bold;
  }
  .step3{
    display: block;
    width: 400px;
    margin: auto;
    text-align: left;
  }
  .step3 input[type="text"],
  .step3 input[type="number"]{
    border: 1px solid white;
    width: 100%;
    padding: 3px 12px;
    border-radius: 2px;
    background: white;
    margin-top: 5px;
    margin-bottom: 2px;
  }
  .step3 select{
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .serviceCurrency{
    position: absolute;
    right: 10px;
    font-weight: bold;
    margin-top: 10px;
    color: #505050;
  }
  .newService{
    position: relative;
  }
  .serviceView{
    background: #13b2b7;
    border: 0px;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .serviceView i{
    cursor: pointer;
    margin-top: -5px;
    display: inline-block;
  }
  .language-title-mobile{
    position: relative !important;
    padding: 0;
    margin: 0;
    margin-left: -15px;
    margin-top: 10px;
  }

@media only screen and (max-width: 556px){
  .step2,
  .step3{
    width: 100% !important;
  }
}
  .top-badge{
    background: #15b0b7;
    color: #fff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: block;
    position: absolute;
    font-size: 10px;
    top: -13px;
    left: 12px;
    line-height: 17px;
    text-align: center;
  }
  .no_products{
    padding: 20px;
    font-weight: bold;
  }
</style>
